import React, { useCallback } from "react"
import {
  CartProvider,
  CustomerProvider,
  SearchProvider,
  AppProvider,
  WindowProvider,
  WindowCtx,
  useActions,
  AppCtx,
} from "@components/contexted"

import { getShippingMethods } from "./app.logic"
import Loader from "./InitLoading"

const onAppInit = (dispatch) => {}

const onInitSearchData = (appLoaded) => (dispatch) => {
  appLoaded()
}

const OuterProviders = ({ children }) => {
  return (
    <WindowProvider>
      <AppProvider onLoad={onAppInit}>{children}</AppProvider>
    </WindowProvider>
  )
}

const InnerProviders = ({ children }) => {
  const { loaded } = useActions(AppCtx, "loaded")
  const onLoadSearchData = useCallback(onInitSearchData(loaded))

  return (
    <CustomerProvider onLoad={onLoadSearchData}>
      <Loader />
      {children}
    </CustomerProvider>
  )
}

export default ({ element }) => {
  return (
    <OuterProviders>
      <InnerProviders>{element}</InnerProviders>
    </OuterProviders>
  )
}

export { WindowCtx }
