// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-standard-gallery-2018-js": () => import("./../../../src/templates/standard/gallery2018.js" /* webpackChunkName: "component---src-templates-standard-gallery-2018-js" */),
  "component---src-templates-standard-gallery-2020-js": () => import("./../../../src/templates/standard/gallery2020.js" /* webpackChunkName: "component---src-templates-standard-gallery-2020-js" */),
  "component---src-templates-standard-gallery-2021-js": () => import("./../../../src/templates/standard/gallery2021.js" /* webpackChunkName: "component---src-templates-standard-gallery-2021-js" */),
  "component---src-templates-standard-gallery-js": () => import("./../../../src/templates/standard/gallery.js" /* webpackChunkName: "component---src-templates-standard-gallery-js" */),
  "component---src-templates-standard-home-2018-js": () => import("./../../../src/templates/standard/home2018.js" /* webpackChunkName: "component---src-templates-standard-home-2018-js" */),
  "component---src-templates-standard-home-2020-js": () => import("./../../../src/templates/standard/home2020.js" /* webpackChunkName: "component---src-templates-standard-home-2020-js" */),
  "component---src-templates-standard-home-2021-js": () => import("./../../../src/templates/standard/home2021.js" /* webpackChunkName: "component---src-templates-standard-home-2021-js" */),
  "component---src-templates-standard-home-js": () => import("./../../../src/templates/standard/home.js" /* webpackChunkName: "component---src-templates-standard-home-js" */),
  "component---src-templates-standard-partners-js": () => import("./../../../src/templates/standard/partners.js" /* webpackChunkName: "component---src-templates-standard-partners-js" */),
  "component---src-templates-standard-vineyards-2018-js": () => import("./../../../src/templates/standard/vineyards2018.js" /* webpackChunkName: "component---src-templates-standard-vineyards-2018-js" */),
  "component---src-templates-standard-vineyards-2020-js": () => import("./../../../src/templates/standard/vineyards2020.js" /* webpackChunkName: "component---src-templates-standard-vineyards-2020-js" */),
  "component---src-templates-standard-vineyards-2021-js": () => import("./../../../src/templates/standard/vineyards2021.js" /* webpackChunkName: "component---src-templates-standard-vineyards-2021-js" */),
  "component---src-templates-standard-vineyards-js": () => import("./../../../src/templates/standard/vineyards.js" /* webpackChunkName: "component---src-templates-standard-vineyards-js" */)
}

