const initialState = {
  // username: "",
  // password: "",
  first_name: "",
  last_name: "",
  address_1: "",
  address_2: "",
  city: "",
  postcode: "",
  country: "Polska",
  email: "",
  phone: "",
  // note: "",
}

export default initialState
